import FavoriteItem from '@/models/FavoriteItem.js'
import store from '@/store'
import Presenter from '@/components/pages/user/item/detail/Presenter'
import constants from '@/constants.js'

export default {
  data: () => ({
    item: {},
    uuid: '',
    countSelectItems: []
  }),

  async created () {
    this.uuid = this.$route.params.uuid
    this.item = await store.dispatch('item/getDetail', this.uuid)
    const selectItems = []
    Array(constants.MAX_COUNT_PER_ITEM).fill().forEach((_, i) => selectItems.push(i + 1))
    this.countSelectItems = selectItems
  },

  render (h) {
    return h(Presenter, {
      props: {
        item: this.item,
        canAddCart: true,
        countSelectItems: this.countSelectItems,
        uuid: this.uuid
      },

      on: {
        addToCart (itemUuid, count, userNote) {
          if (count === 0) {
            return
          }
          const data = {
            itemUuid: itemUuid,
            count: count,
            userNote: userNote
          }
          store.dispatch('cart/addItems', data)
            .then(res => {
              store.commit('updateCartInfo', res)
              store.commit('message/show', {
                message: 'このアイテムをカートに追加しました。',
                color: 'info',
                timeout: 4000
              })
            })
            .catch(error => {
              store.commit('message/show', {
                message: error.response.data.message,
                color: 'error',
                timeout: 6000
              })
            })
        },
        addToFavorite (presenter) {
          const data = {
            itemUuid: presenter.item.uuid
          }
          store.dispatch('favoriteItem/addFavoriteItem', data)
            .then(() => {
              // Changed to true for the reason of switching screen display
              presenter.item.isFavorite = true
              store.commit('message/show', {
                message: 'お気に入りにアイテムを追加しました。',
                color: 'info',
                timeout: 4000
              })
            })
            .catch(error => {
              FavoriteItem.errorHandler(error)
            })
        },
        removeFromFavorite (presenter) {
          const data = presenter.item.uuid
          store.dispatch('favoriteItem/removeFavoriteItem', data)
            .then(() => {
              // Changed to false for the reason of switching screen display
              presenter.item.isFavorite = false
              store.commit('message/show', {
                message: 'お気に入りからアイテムを削除しました。',
                color: 'info',
                timeout: 4000
              })
            })
            .catch(error => {
              FavoriteItem.errorHandler(error)
            })
        }
      }
    })
  }
}
