<template>
  <v-row class="mx-10 mb-10">
    <v-col cols="6">
      <v-img
        v-if="item.mainImgSrc"
        :src="item.mainImgSrc"
        :class="['outset', 'welfare-card', 'mb-6']"
      />
      <v-img
        v-if="item.subImg1Src"
        :src="item.subImg1Src"
        :class="['outset', 'welfare-card', 'mb-6']"
      />
      <v-img
        v-if="item.subImg2Src"
        :src="item.subImg2Src"
        :class="['outset', 'welfare-card', 'mb-6']"
      />
      <v-img
        v-if="item.subImg3Src"
        :src="item.subImg3Src"
        :class="['outset', 'welfare-card', 'mb-6']"
      />
      <v-img
        v-if="item.subImg4Src"
        :src="item.subImg4Src"
        :class="['outset', 'welfare-card']"
      />
    </v-col>
    <v-col cols="6">
      <v-card
        :class="['inset', 'welfare-card', 'px-10', 'py-10']"
      >
        <v-card-title v-text="item.name" />
        <v-card-subtitle>
          カテゴリ：{{ item.categoryName }}
        </v-card-subtitle>
        <v-card-text
          v-if="item.price"
          class="font-weight-bold"
        >
          {{ item.price.toLocaleString() }} pt
        </v-card-text>
        <v-card-text
          v-if="item.expirationPeriod"
        >
          有効期限：交換から {{ item.expirationPeriod }} 日間
        </v-card-text>
        <v-card-text
          v-else
        >
          有効期限：なし
        </v-card-text>
        <v-card-text>
          {{ item.description }}
        </v-card-text>
        <v-select
          v-model="count"
          class="ml-4"
          :items="countSelectItems"
          dense
          label="数量"
          outlined
          style="width: 100px;"
        />
        <!-- TODO cssリファクタリング -->
        <v-card-text
          v-if="item.precaution"
          style="white-space: pre-wrap;"
        >
          <p class="warning--text font-weight-bold mb-0">
            <v-icon class="warning--text">
              mdi-alert
            </v-icon>
            注意事項
          </p>
          {{ item.precaution }}
          <v-checkbox
            v-if="item.precaution"
            v-model="hasConfirmed"
            class="mt-2"
            label="上記注意事項に同意しました"
            color="info"
          />
        </v-card-text>
        <v-textarea
          v-model="userNote"
          class="ml-4"
          outlined
          label="備考"
          hint="担当者への連絡事項など"
        />
        <v-card-actions class="justify-end flex-column align-end">
          <confirm-button
            v-if="item.expirationPeriod"
            button-text="カートに入れる"
            button-color="primary"
            button-icon="mdi-cart-arrow-down"
            button-width="200"
            :button-disabled="disableToAddToCart"
            dialog-title="要確認！"
            dialog-body="このアイテムには有効期限があります。"
            @ok-action="addToCart"
          />
          <v-btn
            v-else
            color="primary"
            rounded
            width="200"
            :disabled="disableToAddToCart"
            class="small-outset mb-3"
            @click="addToCart"
          >
            <v-icon class="mr-2">
              mdi-cart-arrow-down
            </v-icon>
            カートに入れる
          </v-btn>
          <v-btn
            v-if="!item.isFavorite"
            rounded
            outlined
            class="outset mt-3"
            color="secondary"
            width="200"
            @click="addToFavorite"
          >
            <v-icon>
              mdi-heart-plus-outline
            </v-icon>
            お気に入りに追加
          </v-btn>
          <v-btn
            v-else
            outlined
            rounded
            class="outset mt-3"
            color="error"
            width="200"
            @click="removeFromFavorite"
          >
            <v-icon>
              mdi-heart-remove
            </v-icon>
            お気に入りから削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ConfirmButton from '@/components/common/ConfirmButton'

export default {
  components: {
    ConfirmButton
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    uuid: {
      type: String,
      default: ''
    },
    countSelectItems: {
      type: Array,
      default: () => []
    },
    canAddCart: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    count: 0,
    userNote: '',
    hasConfirmed: false
  }),

  computed: {
    disableToAddToCart () {
      return !this.count || (this.item.precaution && !this.hasConfirmed)
    }
  },

  methods: {
    addToCart () {
      this.$emit('addToCart', this.uuid, this.count, this.userNote)
    },
    addToFavorite () {
      this.$emit('addToFavorite', this)
    },
    removeFromFavorite () {
      this.$emit('removeFromFavorite', this)
    }
  }
}
</script>
